export type ProductName = "Mini" | "OfA" | "MiniPay";
export type ProductLineName = "Final" | "Beta" | "Development";
export type ShortProductLineName = "Final" | "Beta" | "Dev";

export class InvalidProductLineIdError extends Error {}

export interface ProductLine {
  id: number;
  name: ProductLineName;
  productName: ProductName;
  productId: number;
  iconUrl: string;
}

export const PRODUCT_LINES: ProductLine[] = [
  {
    id: 1,
    name: "Final",
    productName: "Mini",
    productId: 1,
    iconUrl: "/static/assets/images/icons/mini-final.svg",
  },
  {
    id: 2,
    name: "Beta",
    productName: "Mini",
    productId: 1,
    iconUrl: "/static/assets/images/icons/mini-final.svg",
  },
  {
    id: 3,
    name: "Development",
    productName: "Mini",
    productId: 1,
    iconUrl: "/static/assets/images/icons/mini-final.svg",
  },
  {
    id: 4,
    name: "Final",
    productName: "OfA",
    productId: 2,
    iconUrl: "/static/assets/images/icons/ofa-final.svg",
  },
  {
    id: 5,
    name: "Beta",
    productName: "OfA",
    productId: 2,
    iconUrl: "/static/assets/images/icons/ofa-beta.svg",
  },
  {
    id: 6,
    name: "Development",
    productName: "OfA",
    productId: 2,
    iconUrl: "/static/assets/images/icons/ofa-development.svg",
  },
  {
    id: 7,
    name: "Final",
    productName: "MiniPay",
    productId: 3,
    iconUrl: "/static/assets/images/icons/minipay-final.svg",
  },
  {
    id: 8,
    name: "Beta",
    productName: "MiniPay",
    productId: 3,
    iconUrl: "/static/assets/images/icons/minipay-beta.svg",
  },
  {
    id: 9,
    name: "Development",
    productName: "MiniPay",
    productId: 3,
    iconUrl: "/static/assets/images/icons/minipay-development.svg",
  },
];

export const PRODUCT_LINES_MINI = PRODUCT_LINES.filter(
  (p) => p.productName === "Mini"
);

export const PRODUCT_LINES_OFA = PRODUCT_LINES.filter(
  (p) => p.productName === "OfA"
);

const ID_TO_PRODUCT_LINE = new Map<number, ProductLine>(
  PRODUCT_LINES.map((productLine) => [productLine.id, productLine])
);

export function getProductLine(productLineId: number): ProductLine {
  const productLine = ID_TO_PRODUCT_LINE.get(productLineId);
  if (!productLine) {
    throw new InvalidProductLineIdError(
      `Invalid product line id: ${productLineId}`
    );
  }
  return productLine;
}

export function getShortProductLineName(
  productLineName: ProductLineName
): ShortProductLineName {
  if (productLineName === "Development") {
    return "Dev";
  } else {
    return productLineName;
  }
}
