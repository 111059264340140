import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetSelectOption',
  props: {
    targetName: {},
    affectedUsers: {},
    isDetailedUi: { type: Boolean },
    isRuntime: { type: [Boolean, null] }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_badge = _resolveComponent("q-badge")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.isDetailedUi)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_component_q_badge, {
            "data-test": "targetselect.option.isruntime",
            title: props.isRuntime ? 'runtime' : 'non runtime',
            outline: "",
            dense: "",
            color: props.isRuntime !== null ? 'black' : 'grey',
            class: "q-mx-xs"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: props.isRuntime ? 'hourglass_empty' : 'hourglass_disabled'
              }, null, 8, ["name"])
            ]),
            _: 1
          }, 8, ["title", "color"]),
          _createVNode(_component_q_badge, {
            "data-test": "targetselect.option.affectedusers",
            title: 
          props.affectedUsers
            ? 'affected users'
            : 'affected users (not validated?)'
        ,
            outline: "",
            dense: "",
            color: props.affectedUsers !== null ? 'black' : 'grey',
            class: "q-mx-xs"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "person_outline" }),
              _createTextVNode(" " + _toDisplayString(_ctx.affectedUsers), 1)
            ]),
            _: 1
          }, 8, ["title", "color"])
        ]))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.targetName), 1)
  ]))
}
}

})