import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "in-app" }
const _hoisted_2 = {
  class: "title",
  "data-test": "ia.startpagecardv2.title"
}
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  class: "text",
  "data-test": "ia.startpagecardv2.message"
}
const _hoisted_5 = { class: "buttons-container" }
const _hoisted_6 = {
  class: "primary-button",
  "data-test": "ia.preview.startpagecardv2.primaryText"
}
const _hoisted_7 = ["src"]

import { ImageMetadata } from "@/api/image/typedefs";
import { LottieMetadata } from "@/api/lottie/typedefs";
import inAppPreviewBackgroundUrl from "@/assets/images/inapp-startpagecard.png";
import VisualPreview from "@/components/shared/visualpreview/VisualPreview.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppVisualPreviewStartPageCardV2',
  props: {
    title: {},
    message: {},
    image: {},
    lottie: {},
    primaryText: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VisualPreview, {
    "background-url": _unref(inAppPreviewBackgroundUrl),
    "is-expandable": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.message), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.primaryText), 1)
        ]),
        (_ctx.lottie || _ctx.image)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "image",
              src: _ctx.lottie?.url ?? _ctx.image?.url
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["background-url"]))
}
}

})