import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { Json } from "@/lib/http";
import { cast, TypeCastError } from "@/lib/typing";
import { buildEmptyInAppDisplayWhen } from "@/views/inapps/inAppBuilder";

import { convertImage } from "../image/converters";
import { LottieMetadata, LottieMetadataGuard } from "../lottie/typedefs";
import {
  ExistingInApp,
  ExistingInAppGuard,
  InAppDataPartial,
} from "./typedefs/inApp";

export function convertLottie(data: unknown): LottieMetadata | null {
  if (data === null) return null;

  const lottieData = data as {
    id?: unknown;
    name?: unknown;
    snapshot_url?: unknown;
  };

  if (typeof lottieData !== "object") {
    throw new Error(`Invalid Image data type to convert - ${data}`);
  }

  // TODO(PNS-1738): Drop when BE will start sending camelCase.
  const camelCasedData = camelcaseKeys(lottieData);

  return cast(LottieMetadataGuard, camelCasedData);
}

export function convertExistingInApp(data: unknown): ExistingInApp {
  if (data === null || typeof data !== "object") {
    throw new TypeCastError("data must be an object");
  }

  const responseData: Record<string, unknown> = camelcaseKeys(data);
  if (Array.isArray(responseData.inAppData)) {
    responseData.inAppData = responseData.inAppData.map(
      (item: Record<string, unknown>) => {
        const imageData = item?.["image"];
        const avatarImageData = item?.["avatar_image"];
        const lottieData = item?.["lottie_animation_file"];

        return {
          ...camelcaseKeys(item, { deep: true }),
          ...(imageData ? { image: convertImage(imageData) } : {}),
          ...(avatarImageData
            ? { avatarImage: convertImage(avatarImageData) }
            : {}),
          ...(lottieData
            ? { lottieAnimationFile: convertLottie(lottieData) }
            : {}),
          type: responseData.inAppType,
        };
      }
    );
  }

  // displayWhen returned from BE can be empty string.
  if (responseData.displayWhen === "") {
    responseData.displayWhen = buildEmptyInAppDisplayWhen();
  } else {
    if (typeof responseData.displayWhen !== "string") {
      throw new TypeCastError("display_when must be a string");
    }

    // TODO(PNS-2581): Drop JSON.parse after changes on backend.
    responseData.displayWhen = {
      ...buildEmptyInAppDisplayWhen(),
      ...JSON.parse(responseData.displayWhen),
    };
  }

  return cast(ExistingInAppGuard, responseData);
}
export function convertInAppDataForBackend(
  inAppData: InAppDataPartial["inAppData"]
) {
  const convertedData: Json[] = [];

  for (const item of inAppData) {
    if (
      item.type === "Config Bundle Sheet" ||
      item.type === "Config Bundle Confirm"
    ) {
      convertedData.push({
        ...item,
        avatarImage: item.avatarImage?.id ?? null,
      });
    } else if (item.type === "Bottom Sheet" || item.type === "Sheet") {
      convertedData.push({
        ...item,
        lottieAnimationFile: item.lottieAnimationFile?.id ?? null,
        image: item.image?.id ?? null,
      });
    } else if (item.type === "Center Dialog") {
      convertedData.push({
        ...item,
        lottieAnimationFile: item.lottieAnimationFile?.id ?? null,
        badgeImage: item.badgeImage?.id ?? null,
        centerImage: item.centerImage?.id ?? null,
      });
    } else if (item.type === "Wallpaper Sheet") {
      convertedData.push({
        ...item,
        avatarImage: item.avatarImage?.id ?? null,
        avatarImageDark: item.avatarImageDark?.id ?? null,
        image: item.image?.id ?? null,
      });
    } else if (
      item.type === "Subscription Sheet" ||
      item.type === "Start Page Card"
    ) {
      convertedData.push({
        ...item,
        image: item.image?.id ?? null,
      });
    } else if (item.type === "Start Page Card V2") {
      convertedData.push({
        ...item,
        image: item.image?.id ?? null,
        lottieAnimationFile: item.lottieAnimationFile?.id ?? null,
      });
    } else {
      convertedData.push(item);
    }
  }
  return snakecaseKeys(convertedData, { deep: true });
}
