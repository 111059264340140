import * as t from "io-ts";

import { ImageMetadataGuard } from "@/api/image/typedefs";
import { InAppTypeGuard } from "@/api/inapps/typedefs/inAppTypes";
import { LottieMetadataGuard } from "@/api/lottie/typedefs";

import { InAppButtonActionGuard } from "./inAppButtonAction";

// Types
export type InAppDataAlert = t.TypeOf<typeof InAppDataAlertGuard>;
export type InAppDataConfirm = t.TypeOf<typeof InAppDataConfirmGuard>;
export type InAppDataWebviewPanel = t.TypeOf<typeof InAppDataWebviewPanelGuard>;
export type InAppDataBottomSheet = t.TypeOf<typeof InAppDataBottomSheetGuard>;
export type InAppDataSheet = t.TypeOf<typeof InAppDataSheetGuard>;
export type InAppDataMaintenanceAction = t.TypeOf<
  typeof InAppDataMaintenanceActionGuard
>;
export type InAppDataWallpaperSheet = t.TypeOf<
  typeof InAppDataWallpaperSheetGuard
>;
export type InAppDataWallpaperSheetBasic = t.TypeOf<
  typeof InAppDataWallpaperSheetBasicGuard
>;
export type InAppDataConfigBundleSheet = t.TypeOf<
  typeof InAppDataConfigBundleSheetGuard
>;
export type InAppDataConfigBundleConfirm = t.TypeOf<
  typeof InAppDataConfigBundleConfirmGuard
>;
export type InAppDataCenterDialog = t.TypeOf<typeof InAppDataCenterDialogGuard>;
export type InAppDataSubscriptionSheet = t.TypeOf<
  typeof InAppDataSubscriptionSheetGuard
>;
export type InAppDataStartPageCard = t.TypeOf<
  typeof InAppDataStartPageCardGuard
>;
export type InAppDataStartPageCardV2 = t.TypeOf<
  typeof InAppDataStartPageCardV2Guard
>;

// GUARDS

// Alert
export const InAppDataAlertGuard = t.type({
  type: InAppTypeGuard.keys.Alert,
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  dismissText: t.union([t.string, t.null]),
  dismissAction: t.union([InAppButtonActionGuard, t.null]),
});

// Confirm
export const InAppDataConfirmGuard = t.type({
  type: InAppTypeGuard.keys.Confirm,
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  acceptText: t.union([t.string, t.null]),
  acceptAction: t.union([InAppButtonActionGuard, t.null]),
  cancelText: t.union([t.string, t.null]),
  cancelAction: t.union([InAppButtonActionGuard, t.null]),
});

// Sheet
export const InAppDataSheetGuard = t.type({
  type: InAppTypeGuard.keys.Sheet,
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  primaryButtonText: t.union([t.string, t.null]),
  primaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  secondaryButtonText: t.union([t.string, t.null]),
  secondaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  image: t.union([ImageMetadataGuard, t.null]),
  lottieAnimationFile: t.union([LottieMetadataGuard, t.null]),
});

// Webview Panel
export const InAppDataWebviewPanelGuard = t.type({
  type: InAppTypeGuard.keys["WebView Panel"],
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  url: t.union([t.string, t.null]),
  panelSize: t.union([t.string, t.null]), // TODO(pszyma): Use enum.
});

// Bottom Sheet
export const InAppDataBottomSheetGuard = t.type({
  type: InAppTypeGuard.keys["Bottom Sheet"],
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  primaryButtonText: t.union([t.string, t.null]),
  primaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  secondaryButtonText: t.union([t.string, t.null]),
  secondaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  image: t.union([ImageMetadataGuard, t.null]),
  lottieAnimationFile: t.union([LottieMetadataGuard, t.null]),
});

// Maintenance Action
export const InAppDataMaintenanceActionGuard = t.type({
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  type: InAppTypeGuard.keys["Maintenance Action"],
  mainAction: t.union([InAppButtonActionGuard, t.null]),
});

// Wallpaper Sheet
export const InAppDataWallpaperSheetGuard = t.type({
  type: InAppTypeGuard.keys["Wallpaper Sheet"],
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  primaryButtonText: t.union([t.string, t.null]),
  secondaryButtonText: t.union([t.string, t.null]),
  secondaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  wallpaperIds: t.union([t.string, t.null]),
  themeIds: t.union([t.string, t.null]),
  avatarImage: t.union([ImageMetadataGuard, t.null]),
  avatarImageDark: t.union([ImageMetadataGuard, t.null]),
  centerText: t.union([t.boolean, t.null]),
  image: t.union([ImageMetadataGuard, t.null]),
});

// Wallpaper Sheet Basic
export const InAppDataWallpaperSheetBasicGuard = t.type({
  type: InAppTypeGuard.keys["Wallpaper Sheet Basic"],
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  primaryButtonText: t.union([t.string, t.null]),
  secondaryButtonText: t.union([t.string, t.null]),
  secondaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  wallpaperIds: t.union([t.string, t.null]),
});

// Config Bundle Sheet
export const InAppDataConfigBundleSheetGuard = t.type({
  type: InAppTypeGuard.keys["Config Bundle Sheet"],
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  primaryButtonText: t.union([t.string, t.null]),
  avatarImage: t.union([ImageMetadataGuard, t.null]),
});

export const InAppDataConfigBundleConfirmGuard = t.type({
  type: InAppTypeGuard.keys["Config Bundle Confirm"],
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  subtitle: t.union([t.string, t.null]),
  submessage: t.union([t.string, t.null]),
  confirmButtonText: t.union([t.string, t.null]),
  cancelButtonText: t.union([t.string, t.null]),
  avatarImage: t.union([ImageMetadataGuard, t.null]),
});

// Center Dialog
export const InAppDataCenterDialogGuard = t.type({
  type: InAppTypeGuard.keys["Center Dialog"],
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  badgeImage: t.union([ImageMetadataGuard, t.null]),
  centerImage: t.union([ImageMetadataGuard, t.null]),
  primaryButtonText: t.union([t.string, t.null]),
  secondaryButtonText: t.union([t.string, t.null]),
  primaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  secondaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  lottieAnimationFile: t.union([LottieMetadataGuard, t.null]),
});

// Subscription Sheet
export const InAppDataSubscriptionSheetGuard = t.type({
  type: InAppTypeGuard.keys["Subscription Sheet"],
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  primaryButtonText: t.union([t.string, t.null]),
  primaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  secondaryButtonText: t.union([t.string, t.null]),
  secondaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  image: t.union([ImageMetadataGuard, t.null]),
  offers: t.array(
    t.type({
      id: t.string,
      productId: t.string,
      expirationTime: t.string,
      isPersonalized: t.boolean,
    })
  ),
});

export const InAppDataStartPageCardGuard = t.type({
  type: InAppTypeGuard.keys["Start Page Card"],
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  message: t.union([t.string, t.null]),
  primaryButtonText: t.union([t.string, t.null]),
  primaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  image: t.union([ImageMetadataGuard, t.null]),
  dismissIntervals: t.union([t.string, t.null]),
  order: t.union([t.number, t.null]),
});

export const InAppDataStartPageCardV2Guard = t.type({
  type: InAppTypeGuard.keys["Start Page Card V2"],
  langCode: t.union([t.string, t.literal("default")]),
  langName: t.string,
  title: t.union([t.string, t.null]),
  message: t.union([t.string, t.null]),
  primaryButtonText: t.union([t.string, t.null]),
  primaryButtonAction: t.union([InAppButtonActionGuard, t.null]),
  image: t.union([ImageMetadataGuard, t.null]),
  lottieAnimationFile: t.union([LottieMetadataGuard, t.null]),
});

export type InAppDataLocalizationUploadResult = Record<
  string,
  Record<string, string>
>;
