import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message q-pa-md" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

import QSpinner from 'quasar/src/components/spinner/QSpinner.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { Component, computed, toRefs } from "vue";
import { useRouter } from "vue-router";

import { activateInApp } from "@/api/inapps/activate";
import { castExistingInApp } from "@/api/inapps/casts";
import { createInApp } from "@/api/inapps/create";
import { deleteInApp } from "@/api/inapps/delete";
import { fetchInApp } from "@/api/inapps/details";
import { finishInApp } from "@/api/inapps/finish";
import { pauseInApp } from "@/api/inapps/pause";
import { reactivateInApp } from "@/api/inapps/reactivate";
import {
  InAppTarget,
  isExistingInApp,
  NewInApp,
  NewOrExistingInApp,
} from "@/api/inapps/typedefs/inApp";
import { getAvailableProductLines } from "@/api/inapps/typedefs/inAppTypes";
import { updateInApp } from "@/api/inapps/update";
import InAppForm from "@/components/inapps/detail/InAppForm.vue";
import InAppHeader from "@/components/inapps/detail/InAppHeader.vue";
import ErrorIndicator from "@/components/shared/ErrorIndicator.vue";
import { Config } from "@/composables/typedefs";
import { useUserData } from "@/composables/useUserData";
import { getInAppType } from "@/lib/inapps/inAppType";
import { listsIntersection } from "@/lib/listsOperations";
import { getProductLine } from "@/lib/productLine";
import { isNonEmptyObject } from "@/lib/rules/objectValidator";
import { ReadonlyComputedRef } from "@/lib/typing";
import { useInApp } from "@/views/inapps/useInApp";
import { useInAppActionButtons } from "@/views/inapps/useInAppActionButtons";
import { useInAppFlowMachine } from "@/views/inapps/useInAppFlowMachine";
import { useInAppFormDisabled } from "@/views/inapps/useInAppFormDisabled";
import { useInAppPermissions } from "@/views/inapps/useInAppPermissions";
import { useInAppRouteSync } from "@/views/inapps/useInAppRouteSync";

import { useInAppActions } from "./useInAppActions";
import { useInAppCampaignId } from "./useInAppCampaignId";
import { useInAppCloning } from "./useInAppCloning";
import { useInAppFlowVueMachine } from "./useInAppFlowVueMachine";
import { useInAppPreview } from "./useInAppPreview";
import { useInAppPriority } from "./useInAppPriority";
import { useInAppTarget } from "./useInAppTarget";


export default /*@__PURE__*/_defineComponent({
  __name: 'InApp',
  props: {
    productId: {},
    productLineId: {},
    inAppId: {},
    inAppTypeId: {},
    cloneFromInAppId: { default: null },
    cloneFromProductLineId: { default: null },
    config: {}
  },
  setup(__props: any) {

const $q = useQuasar();

const router = useRouter();

const props = __props;

const {
  productLineId,
  productId,
  inAppId,
  inAppTypeId,
  cloneFromInAppId,
  cloneFromProductLineId,
  config,
} = toRefs(props);

const { user } = useUserData();
const isSuperuser: ReadonlyComputedRef<boolean> = computed(
  () => user.value?.isSuperuser
);

const { inAppFlowMachine } = useInAppFlowMachine({
  fetchInAppCallback: fetchInApp,
  saveInAppCallback: (inApp) =>
    isExistingInApp(inApp)
      ? updateInApp(productLineId.value, inApp, isSuperuser.value)
      : createInApp(productLineId.value, inApp, isSuperuser.value),
  deleteInAppCallback: (inAppId, reason) =>
    deleteInApp(inAppId, productLineId.value, reason),
  activateInAppCallback: (inApp) =>
    activateInApp(productLineId.value, inApp.id),
  pauseInAppCallback: (inApp) => pauseInApp(productLineId.value, inApp.id),
  reactivateInAppCallback: (inApp) =>
    reactivateInApp(productLineId.value, inApp.id),
  finishInAppCallback: (inApp) => finishInApp(productLineId.value, inApp.id),
});

const stateMachine = useInAppFlowVueMachine(inAppFlowMachine);

function onInAppUpdate(newInApp: NewOrExistingInApp) {
  stateMachine.send({ type: "MODIFY", inApp: newInApp });
}

function onTargetUpdate(target: InAppTarget | null) {
  onInAppUpdate({ ...inApp.value, target: target?.id ?? null });

  // Case when target id did not change BUT the target itself has changed.
  // (i.e. has been modified).
  //
  // In that case `useInAppTarget` cannot detect (and refetch) target as for
  // that composable target did not change (id is the same). That is why we
  // need to trigger target load manually.
  // Long term solution would be to store whole target in inApp.target
  // instead of only target id.
  if (target !== null && inApp.value.target === target.id) {
    loadTarget(target.id);
  }
}

const inApp: ReadonlyComputedRef<NewOrExistingInApp> = computed(
  () => stateMachine.state.value.context.inApp
);

const inAppType = computed(() =>
  inAppTypeId.value !== null
    ? getInAppType(inAppTypeId.value)
    : inApp.value?.inAppType ?? null
);

// Initial priority.
const { initialPriority, priorityOptions } = useInAppPriority({
  config,
  inAppType,
});

// Route sync.
const { errorMessage: urlErrorMessage, productLine } = useInAppRouteSync({
  productLineId,
  productId,
  inAppId,
  inAppTypeId,
  inApp,
  changeInAppCallback: (inAppId, productLineId) =>
    stateMachine.send({ type: "LOAD_INAPP", inAppId, productLineId }),
  setNewInAppCallback: (inAppType) =>
    stateMachine.send({
      type: "CREATE_NEW",
      inAppType,
      initialPriority: initialPriority.value,
      defaultTags: config.value.defaultTags,
    }),
  routeReplaceCallback: (route) => router.replace(route),
});

const {
  isLoading: isInAppLoading,
  isTagEditable,
  errorMessage: inAppErrorMessage,
  permissions,
} = useInApp({
  flowState: stateMachine.state,
  useInAppRouteParams: () => ({
    productLineId: computed(() => productLineId.value),
    inAppId: computed(() => inAppId.value),
    productLine: productLine,
  }),
  useInAppPermissions,
  notifyErrorCallback: (errorMessage) =>
    $q.notify({
      type: "negative",
      message: errorMessage,
    }),
});

const productLinesUserCanCloneInto = computed(() =>
  listsIntersection(
    getAvailableProductLines(inApp.value.inAppType),
    permissions.value.productLinesWithAddInAppPermissions
  )
);

const isInAppDisplayWhenSet = computed(
  () =>
    !isDisplayWhenAvailable.value ||
    (isNonEmptyObject(inApp.value.displayWhen.triggers) &&
      isNonEmptyObject(inApp.value.displayWhen.limits))
);

// Targeting.
const { inAppTargetErrorMessage, targetStateMachine, target, loadTarget } =
  useInAppTarget({
    inApp,
    productLine,
  });

// Preview.
const { previewConfig, requestInAppPreview } = useInAppPreview({
  $q,
  productLine: computed(() => productLine.value),
});

// Actions.
const { actionButtonsConfig } = useInAppActionButtons({
  inAppMachineStateMatch: (testedState) =>
    stateMachine.state.value.matches(testedState),
  inAppTargetMachineStateMatch: (testedState) =>
    targetStateMachine.state.value.matches(testedState),
  previewConfig,
  permissions,
  canCloneIntoAnyProductLine: computed(
    () => productLinesUserCanCloneInto.value.length > 0
  ),
  isInAppDisplayWhenSet,
});

const { onAction } = useInAppActions({
  defaultProductLine: productLine,
  productLinesUserCanCloneInto,
  onDeleteCallback: (reason: string) =>
    stateMachine.send({ type: "DELETE", reason }),
  onSaveCallback: () => stateMachine.send({ type: "SAVE" }),
  onValidateCallback: () =>
    targetStateMachine.send({ type: "REQUEST_VALIDATION" }),
  onActivateCallback: () => stateMachine.send({ type: "ACTIVATE" }),
  onPauseCallback: () => stateMachine.send({ type: "PAUSE" }),
  onReactivateCallback: () => stateMachine.send({ type: "REACTIVATE" }),
  onFinishCallback: () => stateMachine.send({ type: "FINISH" }),
  onCloneCallback: (cloneIntoProductLineId) => {
    // TODO(PNS-1709): Simplify once all in app types are implemented.
    router.push({
      name: `InAppCreate${inApp.value.inAppType}`,
      params: {
        productLine: cloneIntoProductLineId,
        product: getProductLine(cloneIntoProductLineId).productId,
      },
      query: {
        cloneFromInAppId: castExistingInApp(inApp.value).id,
        cloneFromProductLineId: productLineId.value,
      },
    });
  },
});

// Cloning.
const { isLoading: isInAppCloneLoading } = useInAppCloning({
  productLineId,
  cloneFromInAppId,
  cloneFromProductLineId,
  onFetchInAppCallback: (productLineId, inAppId) =>
    fetchInApp(productLineId, inAppId),
  onLoadNewInApp: (inApp: NewInApp) =>
    stateMachine.send({ type: "CREATE_NEW_CLONED", inApp }),
  $q,
});

// Form.
const onFormValidationError = async (component: Component) => {
  $q.notify({ type: "negative", message: "Some fields are incorrect." });

  // TODO(pszyma): This can be functional component.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const element = (component as any)?.$el as Element | undefined;
  element?.scrollIntoView({ behavior: "smooth" });
};

// Campaign ID.
useInAppCampaignId({
  inApp,
  routeQuery: computed(() => router.currentRoute.value.query),
  onInAppUpdate,
  onRouteQueryUpdate: (query) =>
    router.replace({ ...router.currentRoute.value, query }),
});

const errorMessage = computed(
  () =>
    urlErrorMessage.value ??
    inAppErrorMessage.value ??
    inAppTargetErrorMessage.value
);

const isLoading = computed(
  () => isInAppLoading.value || isInAppCloneLoading.value
);

const { isDisabled } = useInAppFormDisabled({
  inAppMachineStateMatch: (stateToMatch) =>
    stateMachine.state.value.matches(stateToMatch),
  inApp,
  permissions,
});

const isDisplayWhenAvailable = computed(() => {
  // PNS-3547 Hide DisplayWhen section for `Start Page Card V2`
  return inApp.value.inAppType !== "Start Page Card V2";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(productLine) !== null)
      ? (_openBlock(), _createBlock(InAppHeader, {
          key: 0,
          "product-line": _unref(productLine)
        }, null, 8, ["product-line"]))
      : _createCommentVNode("", true),
    (errorMessage.value !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(ErrorIndicator, {
            message: errorMessage.value,
            "data-test": "ia.error"
          }, null, 8, ["message"])
        ]))
      : (isLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(QSpinner), {
              "data-test": "ia.loading",
              size: "xl",
              color: "primary"
            })
          ]))
        : (_unref(productLine) !== null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(InAppForm, {
                target: _unref(target),
                "is-add-image-button-visible": _unref(permissions).hasAddImagePermission,
                "is-add-lottie-button-visible": _unref(permissions).hasAddLottiePermission,
                "is-tag-editable": _unref(isTagEditable),
                "is-disabled": _unref(isDisabled),
                "is-superuser": isSuperuser.value,
                "product-line": _unref(productLine),
                "action-buttons-config": _unref(actionButtonsConfig),
                "in-app": inApp.value,
                "is-display-when-available": isDisplayWhenAvailable.value,
                "priority-options": _unref(priorityOptions),
                "onUpdate:target": onTargetUpdate,
                "onUpdate:inApp": onInAppUpdate,
                onAction: _unref(onAction),
                onPreview: _cache[0] || (_cache[0] = ($event: any) => (_unref(requestInAppPreview)(_unref(productLineId), inApp.value, $event))),
                onValidationError: onFormValidationError
              }, null, 8, ["target", "is-add-image-button-visible", "is-add-lottie-button-visible", "is-tag-editable", "is-disabled", "is-superuser", "product-line", "action-buttons-config", "in-app", "is-display-when-available", "priority-options", "onAction"])
            ]))
          : _createCommentVNode("", true)
  ]))
}
}

})